@mixin breakpoint($point) {
  @if $point == small-mobile {
    @media only screen and (max-width: 375px) {
      @content;
    }
  }
  @if $point==mobile {
    @media only screen and (max-width: 1100px) {
      @content;
    }
  }
  @if $point==tablet {
    @media only screen and (max-width: 960px) {
      @content;
    }
  }
  @if $point==laptop {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
}
