@import "../src/assets/styles/mixins";
@import "../src/assets/fonts/fonts";
@import "../src/assets/styles/normalize";
@import "../src/assets/styles/header";
@import "../src/assets/styles/main-container";
@import "../src/assets/styles/map-container";
@import "../src/assets/styles/stops-table";
@import "../src/assets/styles/react-datepicker";
@import "../src/assets/styles/react-select";
@import "../src/assets/styles/leaflet";

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  margin: 0;
}

* {
  box-sizing: border-box;
  -webkit-user-drag: none;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

button {
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
