.map-container {
  min-height: 100%;
}
.map {
  padding-bottom: 12px;
  position:relative;
  .mapService {
    position: absolute;
    z-index: 999;
    top: 20px;
    right: 20px;
    text-align: right;
    font-size: 23px;
    background-color: #e11c24;
    padding: 8px;
    color: #fff;
    min-width: 42px;
    text-align: center;
  }
  .mapService--CV {
    background-color: #0081cc;
  }
}