.main-container {
  background: #fff;
  padding: 0 10px 0 14px;
  height: 35px;
  border-radius: 5px 5px 0px 0px;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button-stops {
    height: 34px;
    line-height: 34px;
    width: 110px;
    border: 1px solid #fff;
    background-color: #eee;
    border-radius: 3px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #e11c24;
  }

  .see-map {
    color: #e11c24;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    display: inline-block;
    padding-top: 5px;
    margin-right: 10px;
    letter-spacing: 0.28px;
    @include breakpoint(mobile) {
      display: none;
    }
    &--mobile {
      display: none;
      @include breakpoint(mobile) {
        display: block;
      }
    }
  }

  .see-stops {
    color: #e11c24;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    display: inline-block;
    padding-top: 5px;
    margin-left: 10px;
    letter-spacing: 0.28px;
  }

  .switcher {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    float: left;
    padding-right: 5px;
  }
}

.leaflet-container {
  height: 535px;
  width: 100%;
  z-index: 0;

  @include breakpoint(mobile) {
    height: 450px;
  }
}

.main-container-TD {
  .see-stops,
  .see-map {
    color: #009f3c;
  }
}

.main-container-CV {
  .see-stops,
  .see-map {
    color: #0096db;
  }
}

.no-timetable-header {
  width: 100%;
  background: #f4f4f4;
  padding: 30px 60px;

  .no-timetable {
    text-align: center;
    color: #e11c24;
    font-size: 21px;
    font-weight: 800;
    background-color: #cccccc;
    padding: 20px;
  }

  .no-timetable-TD {
    color: #009f3c;
  }
  
  .no-timetable-CV {
    color: #0096db;
  }
}

.container-details {
  div {
    padding: 0 10px 0 14px;
    position: relative;
    font-size: 14px;
    color: #4f4f4f;
    padding-top: 5px;
  }

  .via {
    text-align: right;
    @include breakpoint(mobile) {
      text-align: left;
    }
    min-height: 22px;
  }

  .timetable-change {
    text-align: left;
    float: left;
    z-index: 999;
    @include breakpoint(mobile) {
      padding-bottom: 1px;
      z-index: 0;
      float: none;
    }
  }
}