.header {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  max-height: 70px;
  padding: 14px 10px 12px 14px;
  background-color: #e11c24;
  margin-bottom: 12px;

  &__hidden {
    display: none;
  }
  
  &__search {
    margin: 0;
    padding: 15px 15px 0;
    color: #fff;
    @include breakpoint(mobile) {
      position: relative;
      bottom: -15px;
      margin-top: -10px !important;
    }
  }

  @include breakpoint(mobile) {
    flex-direction: column;
    align-items: start;
    margin: 15px 15px 15px 15px;
    max-height: initial;
  }

  &__stop {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    height: 43px;
    line-height: 43px;
    background-color: #ffffff;
    border-radius: 3px;
    text-align: center;
    margin-right: 12px;
    color: #4d4d4d;
    padding: 0 15px;
    &__single {
      width: 63px;
      min-width: 63px;
      max-width: 63px;
      padding: 0;
    }
    &__small {
      font-size: 12px;
    }
    @include breakpoint(mobile) {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .form-container {
    display: -webkit-flex;
    display: flex;
    width: calc(70% - 55px);
    position: relative;

    @include breakpoint(mobile) {
      width: 100%;
      flex-direction: column;
      margin-bottom: 11px;
    }
  }

  .form-select {
    margin-bottom: 8px;
  }

  .search-container {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    height: 43px;

    @include breakpoint(mobile) {
      flex-direction: column;
      height: initial;
    }

    .react-select__menu {
      z-index: 99999;
      position: absolute;
    }

    .react-select--is-disabled {
      right: 16px;
      @include breakpoint(mobile) {
        right: 0px;
      }
    }

    .react-select__control--is-disabled {
      background-color: #95131c;
      border-color: #95131c;
    }

    .react-select__single-value--is-disabled {
      color: #fff;
    }

    .clear-input {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 14px;
      right: 28px;
    }

    .change-direction {
      position: relative;
      height: 30px;
      min-width: 29px;
      top: 7px;
      right: 7px;
      z-index: 9999;

      @include breakpoint(mobile) {
        position: absolute;
        top: 33px;
        left: auto;
        right: 25px;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }
    }
  }

  img {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }

  .react-select__control {
    margin-right: 8px;
    @include breakpoint(mobile) {
      margin-right: 0px;
    }
  }
}

.header-CV {
  background-color: #0081cc;

  .search-container {
    .react-select__control--is-disabled {
      background-color: #0060ae;
      border-color: #0060ae;
    }
  }
}

.wrapper-download-pdf {
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  .button-download-pdf {
    position: relative;
    height: 35px;
    line-height: 35px;
    border-radius: 3px;
    background-color: #d6d6d6;
    border: none;
    font-size: 12px;
    background-color: #ff0000;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: 0px;
    padding: 0 35px 0 20px;
    border: solid 1px;

    @include breakpoint(mobile) {
      font-size: 11px;
    }
    &:after {
      content: "";
      position: absolute;
      background-image: url("../../assets/images/icons/download-icon.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 12px;
      height: 14px;
      top: 10px;
      right: 17px;
    }
  }
}

.wrapper-download-pdf-week {
  display: block;
  color: #fff;
  &--first {
    float: left;
    padding-right: 10px;
    @include breakpoint(mobile) {
      float: none;
      padding-right: 0px;
    }
  }
}

.wrapper-download-pdf-hidden {
  display: none;
}
.wrapper-download-pdf-CV {
  .button-download-pdf {
    background-color: #0096db;
  }
}

.map-hidden {
  display: none;
}

.header-buttons {
  display: block;
  max-height: 90px;
  @include breakpoint(mobile) {
    max-height: initial;
  }
  .button-download-pdf {
    margin-top: 12px;
  }
}