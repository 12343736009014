.react-datepicker-wrapper {
  .react-datepicker {
    &__input-container {
      position: relative;

      input {
        max-width: 175px;
        min-width: 175px;
        border: none;
        border-radius: 3px;
        height: 43px;
        padding: 0 12px;
        font-family: "Soleil-Book", sans-serif;
        font-size: 16px;
        color: #4D4D4D;

        @include breakpoint(mobile) {
          width: 100%;
          max-width: 100%;
        }

        &:active,
        &:hover,
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.header-CV {
  .react-datepicker-wrapper {
    .react-datepicker {
      &__input-container {
        &:after {
          background-image: url("../images/icons/CV/calendar.svg");
        }
      }
    }
  }
}

img.change-date {
  position: absolute;
  top: 4px;
  right: 5px;
  @include breakpoint(mobile) {
    position: relative;
    top: 12px;
    right: 40px;
  }
}

.react-datepicker-popper {
  z-index:9999;
}

.date-picker {
  position: relative;
}