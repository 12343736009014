.stops-table-scrollable {
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0px);

  .stops-table {
    tbody {
      tr.timing-point {
        font-style: normal;
      }
      tr.point {
        font-style: italic;
      }
      tr {
        td {
          &:nth-child(2) {
            color: #fff;
          }

          &:nth-child(3) {
            border: none;
          }
        }
      }

      tr {
        &:nth-child(odd) {
          td {
            &:nth-child(2) {
              color: #f4f4f4;
            }
          }
        }
      }
    }

    .stops-collapse {
      display: none;
    }
  }
}

.stops-table {
  width: calc(100% + 1px);
  border-spacing: 0;
  border-collapse: collapse;

  tbody {
    tr {
      height: 60px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      letter-spacing: -0.39px;
      background-color: #ffffff;
      color: #333333;

      @include breakpoint(mobile) {
        height: 85px;
      }

      &:nth-child(odd) {
        background: #f4f4f4;
      }

      &:last-child {
        td {
          &:first-child {
            &:before {
              content: none;
            }
          }
        }
      }

      td {
        padding: 0 12px;
        position: relative;
        text-align: right;
        
        &:first-child {
          width: 30px;

          &:before {
            content: "";
            position: absolute;
            display: block;
            background-repeat: repeat-y;
            width: 50%;
            height: 100%;
            top: 50%;
            background-image: url("../images/line-gray.svg");
            background-position: center;
            background-size: 12px 100px;
          }
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          &:first-child {
            &:before {
              height: 77px;
              top: auto;
            }
          }
        }

        &:nth-child(2) {
          text-align: left;
          min-width: 300px;
          width: 250px;
          padding: 0;
          font-weight: 800;
          line-height: 23px;
          .subTitle {
            font-weight: 100;
          }
          @include breakpoint(mobile) {
            min-width: 150px;
            width: 150px;
          }
        }

        &:nth-child(3) {
          width: 20px;
          min-width: 80px;
          border-right: 1px solid #dbdbdb;

          @include breakpoint(mobile) {
            min-width: 30px;
            width: 30px;
          }
        }
      }

      .time {
        font-family: sans-serif;
        min-width:80px;
        @include breakpoint(mobile) {
          min-width: 70px;
        }
        &--single {
          text-align: left;
          padding-left: 31px;
        }
        padding: 0px;
        padding-right: 13px;
        vertical-align: middle;
      }
      
      .center {
        text-align: center;
      }

    }
    .service {
      height: 40px;
      font-weight: 600;
      td {
        &:first-child {
          &:before {
            background-image: none;
          }
        }
      }
    }

  }

  .stops-tracking-container {
    display: -webkit-flex;
    display: flex;
  }

  .stops-tracking {
    text-align: center;
  }

  .stops-tracking-dot {
    display: inline-block;
    position: relative;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 3px solid #10bd59;
    background-color: #9ee4bc;

    &--finish {
      border: 3px solid #d01717;
      background-color: #eca3a3;
    }

    &--between {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 3px solid #d3d3d3;
      background-color: #ffffff;
    }

    &--tp {
      width: 18px;
      height: 18px;
    }
  }

  .stops-collapse {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }

    &--down {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }
}

.stops-table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  height: 52px;
  padding: 0 14px;
  background-color: #c01924;
  border-top: #95131c solid 5px;
  display: flex;
  display: -webkit-flex;

  &__stops {
    min-width: 416px;
    border-right: 1px solid #dbdbdb;

    @include breakpoint(mobile) {
      min-width: 130px;
      border: none;
    }
  }

  &__times {
    line-height: 52px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
    @include breakpoint(mobile) {
      padding-left: 0px;
      justify-content: flex-end;
      span {
        display: none;
      }
    }
  }
  .wrapper-download-pdf {
    float: left;
    .button-download-pdf {
      top: 5px;
    }
  }
}

.scroll-buttons {
  .button-scroll,
  .button-scroll-later {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 12px;
    background-color: #f2f2f2;
    color: #e11c24;
    border: none;
    border-radius: 3px;
    height: 30px;
    width: 76px;
    position: relative;
    padding-left: 14px;
    bottom: 6px;

    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 6px;
      background-image: url("../images/icons/arrow.svg");
      background-size: contain;
      width: 12px;
      height: 10px;
    }

    &:first-child {
      margin-right: 8px;
    }
  }

  .button-scroll-later {
    padding-left: 0;
    padding-right: 2px;

    &:before {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      left: 58px;
    }
  }
}

.stops-table-pinned {
  tr.timing-point {
    font-style: normal;
  }
  tr.point {
    font-style: italic;
  }

  position: absolute;
  left: 0;
  top: 0;
}

.stops-table-holder {
  position: relative;
  overflow: hidden;
}

.stops-table-header-CV {
  background-color: #0081cc;
  border-top: #0096db solid 5px;
  .scroll-buttons {
    .button-scroll,
    .button-scroll-later {
      color: #0096db;

      &:before {
        background-image: url("../images/icons/CV/arrow.svg");
      }
    }
  }
}

.layover {
  font-family: sans-serif;
  font-weight: 600;
  font-size: 12px;
  padding-right: 1px;
  position: relative;
  bottom: 2px;
}

.no-time {
  @include breakpoint(mobile) {
    padding-left: 30px;
  }
  padding-left: 40px;
}

.time_colon {
    display: none;
}

.legend {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    background-color: #ffffff;
    color: #333333;
    padding: 10px;
    .label {
      font-weight: bold;
      font-size: 18px;
      padding-bottom: 10px;
    }
    .section {
      &--first {
        float: left;
      }
      margin-right: 50px;
      .item {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        img {
          margin: 5px 5px 5px 0px;
        }
      }
    }
    .italic {
      font-style: italic;
    }
}