.map {
  .leaflet-popup-content-wrapper {
    border-radius: 3px;

    .leaflet-popup-content {
      width: 180px;
      margin: 16px 12px 24px 30px;

      h3 {
        font-family: "Soleil-Book", sans-serif;
        font-size: 15px;
        color: #606060;
        margin: 0 0 10px 0;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          background-image: url("../images/stop.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 15px;
          height: 25px;
          left: -18px;
          top: -2px;
        }
      }

      .stop-blue {
        &:before {
          background-image: url("../images/stop-blue.svg");
        }
      }

      .origin {
        &:before {
          background-image: url("../images/stop-start.svg");
        }
      }

      .dest {
        &:before {
          background-image: url("../images/stop-finish.svg");
        }
      }

      h4 {
        display: inline-block;
        font-family: "Soleil-Regular", sans-serif;
        font-size: 12px;
        color: #7c7c7c;
        letter-spacing: 0.12px;
        margin: 0 0 36px 0;
      }

      a {
        display: inline-block;
        font-family: "Soleil-Regular", sans-serif;
        font-size: 14px;
        color: #e11c24;
        position: relative;
        background: #f1f1f1;
        border-radius: 5px;
        padding: 8px 20px 4px 10px;
        font-weight: 800;
        text-decoration: none;
        &:after {
          content: "";
          position: absolute;
          top: 12px;
          right: 7px;
          background-image: url("../images/icons/arrow-icon.svg");
          background-size: contain;
          width: 10px;
          height: 10px;
        }
      }
      .link-TD {
        color: #009f3c;
        &:after {
          background-image: url("../images/icons/TD/arrow-icon.svg");
        }
      }
      .link-CV {
        color: #0096db;
        &:after {
          background-image: url("../images/icons/CV/arrow-icon.svg");
        }
      }
    }
  }
}
